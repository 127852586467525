/**
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 20:08:55 GMT
 */

:root {
  --border-radius-sm: 0.25rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 1rem;
  --border-radius-round: 6.25rem;
  --color-green-100: #dae7d6;
  --color-green-200: #b4cead;
  --color-green-300: #8fb685;
  --color-green-400: #699d5c;
  --color-green-500: #448533;
  --color-green-600: #396e2f;
  --color-green-700: #2e572b;
  --color-green-800: #234027;
  --color-green-900: #182923;
  --color-green-050: #ecf3eb;
  --color-yellow-100: #fef0cd;
  --color-yellow-200: #fce09c;
  --color-yellow-300: #fbd16a;
  --color-yellow-400: #fac138;
  --color-yellow-500: #e4aa1b;
  --color-yellow-600: #c79e3b;
  --color-yellow-700: #987b34;
  --color-yellow-800: #6a582d;
  --color-yellow-900: #3b3526;
  --color-yellow-050: #fef9ec;
  --color-red-100: #f3d7d7;
  --color-red-200: #e6afae;
  --color-red-300: #da8686;
  --color-red-400: #cd5e5d;
  --color-red-500: #c13635;
  --color-red-600: #9d2f31;
  --color-red-700: #79282c;
  --color-red-800: #552028;
  --color-red-900: #311923;
  --color-red-050: #f9ebeb;
  --color-grey-100: #e2e3e4;
  --color-grey-200: #c5c6c9;
  --color-grey-300: #a8aaaf;
  --color-grey-400: #8b8d94;
  --color-grey-500: #6e7179;
  --color-grey-600: #5b5e67;
  --color-grey-700: #474b55;
  --color-grey-800: #2d3039;
  --color-grey-900: #1d212b;
  --color-grey-020: #fafafa;
  --color-grey-050: #f0f1f1;
  --color-grey-a100: #d5d5d5;
  --color-grey-a200: #aaaaaa;
  --color-grey-a400: #616161;
  --color-grey-a700: #303030;
  --color-eggplant-100: #dfdafe;
  --color-eggplant-200: #bfb6fd;
  --color-eggplant-300: #9f91fd;
  --color-eggplant-400: #7f6dfc;
  --color-eggplant-500: #5f48fb;
  --color-eggplant-600: #4f3dcf;
  --color-eggplant-700: #3e32a3;
  --color-eggplant-800: #2e2877;
  --color-eggplant-900: #1d1d4b;
  --color-eggplant-050: #efedff;
  --color-blue-100: #d1e4f6;
  --color-blue-200: #a3c8ed;
  --color-blue-300: #75ade4;
  --color-blue-400: #4791db;
  --color-blue-500: #1976d2;
  --color-blue-600: #1762ae;
  --color-blue-700: #144e8a;
  --color-blue-800: #123a67;
  --color-blue-900: #0f2643;
  --color-blue-050: #e8f1fa;
  --color-kale-100: #cceffc;
  --color-kale-200: #99dff9;
  --color-kale-300: #66d0f6;
  --color-kale-400: #33c0f3;
  --color-kale-500: #00b0f0;
  --color-kale-600: #0390c6;
  --color-kale-700: #05719c;
  --color-kale-800: #085173;
  --color-kale-900: #0a3249;
  --color-kale-050: #e5f7fd;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-blank: rgba(255, 255, 255, 0.01);
  --color-opacity-1: 08;
  --color-opacity-2: 12;
  --color-opacity-3: 30;
  --color-opacity-4: 40;
  --color-opacity-5: 50;
  --color-opacity-6: 60;
  --color-opacity-7: 70;
  --color-opacity-8: 80;
  --color-opacity-9: 90;
  --color-opacity-05: 04;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-bolder: 900;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.75rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.25rem;
  --font-size-6xl: 2.625rem;
  --font-size-7xl: 3rem;
  --font-size-8xl: 6rem;
  --font-heading1: 3rem;
  --letter-spacing-xs: -0.08rem;
  --letter-spacing-sm: -0.03rem;
  --letter-spacing-reg: 0;
  --letter-spacing-lg: 0.01rem;
  --letter-spacing-xl: 0.05rem;
  --line-height-single: 1;
  --line-height-short: 1.2;
  --line-height-long: 1.5;
  --line-height-double: 2;
  --size-8xs: 1rem;
  --size-7xs: 1.5rem;
  --size-6xs: 2rem;
  --size-5xs: 3rem;
  --size-4xs: 4rem;
  --size-3xs: 8rem;
  --size-2xs: 15.625rem;
  --size-xs: 21.875rem;
  --size-sm: 37.5rem;
  --size-md: 56.25rem;
  --size-lg: 75rem;
  --size-xl: 96rem;
  --spacing-4xs: 0.0625rem;
  --spacing-3xs: 0.125rem;
  --spacing-2xs: 0.25rem;
  --spacing-xs: 0.5rem;
  --spacing-sm: 0.75rem;
  --spacing-none: 0;
  --spacing-base: 1rem;
  --spacing-lg: 1.25rem;
  --spacing-2lg: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-2xl: 2.5rem;
  --spacing-3xl: 3rem;
  --spacing-4xl: 4rem;
  --spacing-5xl: 5rem;
  --spacing-6xl: 6rem;
  --spacing-7xl: 10rem;
  --spacing-margin-bottom: 1.25rem;
  --brand-color-primary: Eggplant;
  --brand-color-secondary: Kale;
  --brand-color-error: Red;
  --brand-color-warning: Yellow;
  --brand-color-success: Green;
  --brand-color-info: Blue;
}
